<template>
  <v-card
    flat
    class="rounded-0 fill-height d-flex align-stretch justify-center"
    :class="{ 'flex-column': !mdAndUp }"
  >
    <v-sheet
      min-height="100vh"
      :class="{ fullwidth: !mdAndUp, halfwidth: mdAndUp }"
      class="d-flex px-10"
      color="primary"
    >
      <v-card
        flat
        max-width="450"
        color="transparent"
        class="align-self-center mx-auto fullwidth"
      >
        <v-card-title class="d-flex fullwidth flex-column">
          <v-img
            :lazy-src="settings.loader"
            max-width="300"
            :src="settings.longLogoWhite"
          ></v-img>
          <h2 class="light--text fw-900 fs-25">Login</h2>
        </v-card-title>
        <v-form v-model="valid">
          <v-card-text>
            <v-row dense>
              <label class="light--text fw-700 fs-15">Email</label>
              <v-col cols="12">
                <v-text-field
                  v-model="email"
                  :rules="requiredEmailRules"
                  dense
                  color="white"
                  filled
                  class=""
                  dark
                  hide-details="auto"
                  outlined
                  prepend-inner-icon="mdi-email"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <label class="light--text fw-700 fs-15">Password</label>
              <v-col cols="12">
                <v-text-field
                  :type="showPassword ? 'text' : 'password'"
                  v-model="password"
                  :rules="requiredRules"
                  dense
                  color="white"
                  filled
                  class=""
                  dark
                  hide-details="auto"
                  outlined
                  prepend-inner-icon="mdi-lock"
                  append-icon="mdi-eye"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense align="center" justify="center">
              <v-col cols="12">
                <v-btn
                  link
                  small
                  depressed
                  class="text-none light--text"
                  text
                  @click="forgotPassword"
                >
                  Forgot password?
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex fullwidth flex-column">
            <v-btn
              color="accent"
              elevation="5"
              class="fw-900 rounded-20 py-5 mb-3 light--text ls-unset fs-20"
              :disabled="!valid"
              block
              @click="login"
            >
              LOG IN
            </v-btn>
            <div class="my-2 light--text" v-if="signup_enabled">
              <label>
                No account yet?
                <v-btn
                  link
                  depressed
                  class="text-none light--text"
                  text
                  @click="goSignup"
                >
                  Signup here
                </v-btn>
              </label>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-sheet>
    <v-sheet
      :min-height="mdAndUp ? `100vh` : ``"
      class="d-flex fill-height fullwidth"
      color="white"
    >
      <v-img
        :lazy-src="settings.loader"
        contain
        :src="require('@/assets/login/gallery-2.png')"
      ></v-img>
    </v-sheet>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  inheritAttrs: false,
  data: () => ({
    valid: false,
    showPassword: false,
    email: null,
    password: null
  }),
  computed: {
    ...mapGetters('app', ['allowedModules']),
    signup_enabled() {
      return this.allowedModules.includes('signup')
    },
    redirect_uri() {
      return this.$route.query.hasOwnProperty('redirect_uri')
        ? this.$route.query.redirect_uri
        : null
    },
    payload() {
      return {
        email: this.email,
        password: this.password,
        redirect_url: this.redirect_uri
      }
    }
  },
  methods: {
    goToSite() {
      window.location = this.settings.wp_site
    },
    goSignup() {
      if (this.signup_enabled) {
        this.$router.push({
          name: 'signup',
          params: { redirect_uri: this.redirect_uri }
        })
      } else {
        this.appSnackbar(
          'Signup is temporary unavailable. Please try again later',
          'error'
        )
      }
    },
    login() {
      this.$auth.login(
        this.payload,
        (data) => {
          if (data && data.redirect_url && data.redirect_url !== 'null')
            window.location = data.redirect_url
          else this.$router.push({ name: 'default-content' })
        },
        (error) => {
          console.log(error)
        }
      )
    },
    forgotPassword() {
      this.$router.push({
        name: 'forgot-password',
        query: { redirect_uri: this.redirect_uri }
      })
    }
  }
}
</script>

<style></style>
